import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Java Syntax Related Changes Since Java SE 6",
  "date": "2014-08-22T02:22:44-05:00",
  "tags": ["android", "java", "jdk"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`In this post I am going to discuss about JDK related changes since version 6 and how it may affect Android development.`}</p>
    <h2>{`What’s New in Java SE 7 Syntax`}</h2>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Binary Literals`}</p>
        <p parentName="li">{`We may display `}<strong parentName="p">{`0b`}</strong>{` or `}<strong parentName="p">{`0B`}</strong>{` in front of a number to indicate a binary number. E.g.`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`int aNum = 0b00100001 // 33
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Underscore in Numeric Literals`}</p>
        <p parentName="li">{`We can separate a group of numbers with underscore in between digits to make it more readable, and the actual value is the same as without underscore`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`long ccNumber = 1111_1111_1111_1111L // which is the same as 1111111111111111L
`}</code></pre>
        <p parentName="li"><strong parentName="p">{`Note`}</strong></p>
        <ol parentName="li">
          <li parentName="ol">{`We cannot add underscore before and after decimal point`}</li>
          <li parentName="ol">{`We cannot add underscore as a prefix to a number (this make it as identifier and not a numeric literal)`}</li>
          <li parentName="ol">{`We cannot add underscore as a suffix to a number`}</li>
          <li parentName="ol">{`We cannot add underscire in the 0x radix prefix`}</li>
        </ol>
      </li>
      <li parentName="ul">
        <p parentName="li">{`String in Switch Statement`}</p>
        <p parentName="li">{`In Java SE 6 if we want to do a conditional statement on a String we need to do it in a if…else Statement`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`if(a.equals("apple")) {
    // do something
} else if (a.equals("orange")) {
  // do another thing
} else if (a.equals("pear")) {
  // do some other thing
} else {
  // do something when above conditions do not match
}
`}</code></pre>
        <p parentName="li">{`In Java SE 7 we can wrap it into a Switch Statement`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`switch(a) {
    case "apple":
        //do something
        break;
    case "orange":
        // do something
        break;
    case "pear":
        // do somethin
        break;
    default:
    // do something
      break;
}
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Type Inference for Generic instance Creation (a.k.a Diamond)`}</p>
        <p parentName="li">{`In Java SE 6 we can create a generic collection like a list of Strings in the following syntax`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`List<String> strCollections = new ArrayList<String>();
`}</code></pre>
        <p parentName="li">{`In Java SE 7 we can create the same list with a shorter syntax`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`List<String> strCollections = new ArrayList<>();
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Improved Compiler Warnings and Errors When Using Non-Reifiable Formal Parameters with Varargs Methods`}</p>
        <p parentName="li">{`see `}<a parentName="p" {...{
            "href": "http://docs.oracle.com/javase/7/docs/technotes/guides/language/non-reifiable-varargs.html"
          }}>{`http://docs.oracle.com/javase/7/docs/technotes/guides/language/non-reifiable-varargs.html`}</a>{` for more details.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Try-with-Resources`}</p>
        <p parentName="li">{`Java SE 6`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`BufferedReader br;
try {
    br = new BufferedReader(...);
    br.readLine();
} catch (IOException e) {
    // do something
} finally {
    if (br != null) {
        br.close();
    }
}
`}</code></pre>
        <p parentName="li">{`Java SE 7`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`try(BufferedReader br = new BufferedReader(...)) {
  br.readLine();
} catch (IOException e) {
  // do something
}
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Catch Multiple Exception Types and Rethrowing with Improved Type Checking`}</p>
        <p parentName="li">{`Java SE 6`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`Class string;
try{
  string = Class.forName("java.lang.String");
  string.getMethod("length").invoke("some error");
} catch(ClassNotFoundException cnfe) {
  System.out.println(cnfe.getMessage());
} catch(IllegalAccessException iae1) {
  System.out.println(iae1.getMessage());
} catch(IllegalArgumentException iae2) {
  System.out.println(iae2.getMessage());
} catch(InvocationTargetException ite) {
  System.out.println(ite.getMessage());
} catch(SecurityException se) {
  System.out.println(se.getMessage());
} catch(NoSuchMethodException nsme) {
System.out.println(nsme.getMessage());
}
`}</code></pre>
        <p parentName="li">{`Java SE 7`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`try {
  Class s = Class.forName("java.lang.String");
  s.getMethod("length").invoke("some error");
} catch (ClassNotFoundException | IllegalAccessException
        | IllegalArgumentException | InvocationTargetException
        | SecurityException | NoSuchMethodException e) {
  //Do something here
  System.out.println(e.getMessage());
}
`}</code></pre>
      </li>
    </ul>
    <p>{`More details about Java SE 7 changes are available on the `}<a parentName="p" {...{
        "href": "http://www.oracle.com/technetwork/java/javase/jdk7-relnotes-418459.html"
      }}>{`Java SE 7 Features and Enhancements`}</a>{`, especially on the `}<a parentName="p" {...{
        "href": "http://docs.oracle.com/javase/7/docs/technotes/guides/language/enhancements.html#javase7"
      }}>{`Java Programming Language Enhancements`}</a></p>
    <h4>{`Can Android Support Java SE 7 Syntax?`}</h4>
    <p>{`According to `}<a parentName="p" {...{
        "href": "http://tools.android.com/tech-docs/new-build-system/user-guide#TOC-Using-sourceCompatibility-1.7"
      }}>{`tools.android.com`}</a>{`, you can use most of them with earlier version of Android with targetSDKVersion of 19 and set source compatibility to Java version 1.7, with the exception of `}<strong parentName="p">{`Try-with-resources`}</strong>{` which you must use minSdkVersion of 19 (Android KitKat).`}</p>
    <h2>{`What’s New in Java SE 8 Syntax`}</h2>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Lambda Expressions`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`processElements(
    roster,
    p -> p.getGender() == Person.Sex.MALE
        && p.getAge() >= 18
        && p.getAge() <= 25,
        p -> p.getEmailAddress(),
        email -> System.out.println(email)
);
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Improved Type Inference`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Type Annotations and Pluggable Type Systems`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Repeating Annotations`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Method Parameter Reflection`}</p>
      </li>
    </ul>
    <p>{`More details of Java SE 8 can be referred to `}<a parentName="p" {...{
        "href": "http://www.oracle.com/technetwork/java/javase/8-whats-new-2157071.html"
      }}>{`What’s New in JDK 8`}</a></p>
    <h4>{`Can Android Support Java SE 8 Syntax?`}</h4>
    <p>{`In short (at the time of this blog post), not at the moment, although you can try with unofficial/third party libraries, such as `}<a parentName="p" {...{
        "href": "https://github.com/evant/gradle-retrolambda"
      }}>{`gradle-retrolambda`}</a>{` for lambda expressions :)`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      